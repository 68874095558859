<template>
  <div id="user-profile">
    <!-- <PartnersProfileHeader :header-data="profileData.header" /> -->
    <!-- profile info -->
    <section id="profile-info">
      <b-row>

        <!-- about suggested page and twitter feed -->
        <b-col
          lg="3"
          cols="12"
          order="2"
          order-lg="1"
        >
          <PartnerProfileAbout />
        </b-col>
        <b-col
          lg="9"
          cols="12"
          order="2"
          order-lg="1"
        >
          <PartnerDashboardOne v-if="_isPartner" />
          <ClientDashboardOne v-if="_isClient" />

          <AdminDashboardOne v-if="_isAdmin" />
        </b-col>
        <b-col
          lg="12"
          cols="12"
          order="2"
          order-lg="1"
        >
          <PartnerDashboardTwo v-if="_isPartnerOrAdmin" />
          <!-- <profile-suggested-pages :pages-data="profileData.suggestedPages" />
          <profile-twitter-feed :twitter-feed="profileData.twitterFeeds" /> -->
        </b-col>
        <!--/ about suggested page and twitter feed -->

        <!-- post -->
        <b-col
          lg="6"
          cols="12"
          order="1"
          order-lg="2"
        >
          <!-- <profile-post :posts="profileData.post" /> -->
        </b-col>
        <!-- post -->

        <!-- latest photos suggestion and polls -->
        <b-col
          lg="3"
          cols="12"
          order="3"
        >
          <!-- <profile-latest-photos :latest-images="profileData.latestPhotos" />
          <profile-suggestion :suggestions="profileData.suggestions" />
          <profile-polls :polls-data="profileData.polls" /> -->
        </b-col>
        <!--/ latest photos suggestion and polls -->

        <!-- load more  -->
        <b-col
          cols="12"
          order="4"
        >
          <!-- <profile-bottom /> -->
        </b-col>
        <!--/ load more  -->
      </b-row>
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import vSelect from 'vue-select'
import PartnersProfileHeader from '../Dashboards/PartnersProfileHeader.vue'
import { mapState, mapMutations, mapGetters } from 'vuex'

/* eslint-disable global-require */
export default {
  watch: {
    'form.select'(value, old) {
      this.CHANGE_RELOAD_QUERY()
      if (!this.loading) return ''
      if (!value) {
        this.form.select = old
        return ''
      }
      this.QUERY_RESULTS()
      return
    },
  },
  computed: {
    ...mapState('dashboardDataStore', ['results', 'loading', 'options', 'form']),
    ...mapGetters('dashboardDataStore', ['_isPartnerOrAdmin', '_isClient', '_isAdmin', '_isPartner']),
  },
  components: {
    BRow,
    BCol,
    PartnersProfileHeader,
    PartnerProfileAbout: () => import('./PartnerProfileAbout.vue'),
    PartnerDashboardOne: () => import('./PartnerDashboardOne.vue'),
    PartnerDashboardTwo: () => import('./PartnerDashboardTwo.vue'),
    ClientDashboardOne: () => import('./Clients/ClientDashboardOne'),
    AdminDashboardOne: () => import('./Admins/AdminDashboardOne'),

    vSelect,
  },
  data() {
    return {
      profileData: {},
    }
  },
  created() {
    // this.$http.get('/profile/data').then(res => {
    //   this.profileData = res.data
    // })
  },
  methods: {
    ...mapMutations('dashboardDataStore', ['QUERY_RESULTS', 'CHANGE_RELOAD_QUERY']),
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss" >
@import '~@core/scss/vue/pages/page-profile.scss';
</style>
